import { arbitrumSepolia, localhost, moonbaseAlpha } from 'wagmi/chains'

import type { Register } from '@app/local-contracts'
import { makeLocalhostChainWithEns } from '@app/utils/chains/makeLocalhostChainWithEns'

export const deploymentAddresses = JSON.parse(
  process.env.NEXT_PUBLIC_DEPLOYMENT_ADDRESSES || '{}',
) as Register['deploymentAddresses']

export const localhostWithEns = makeLocalhostChainWithEns<typeof localhost>(
  localhost,
  deploymentAddresses,
)

export const arbitrumSepoliaWithEns = {
  ...arbitrumSepolia,

  contracts: {
    ensBaseRegistrarImplementation: {
      address: '0x3D64E8934eE6ED16Ef4572E9bF304413cc07dE13',
    },
    ensBulkRenewal: {
      address: '0xe529D4298ac6311356498db2DCbb254c966589FF',
    },
    ensDnsRegistrar: {
      address: '0xC315ed21c58Eb1FB1740C9cBDA5102d096e35C38',
    },
    ensDnssecImpl: {
      address: '0xf8382EEb6057070E7FBbFe4CAe3BbeceF9Aa1201',
    },
    ensEthRegistrarController: {
      address: '0x4C82a6E7D50D148561C4E64cBE172CB8A754b946',
    },
    ensNameWrapper: {
      address: '0x7e2bEfa30D987aF3afEDa7a47148596881E18476',
    },
    ensPublicResolver: {
      address: '0x7aA02f6792c9C6DdD6085876df5f6FDB899Fa76e',
    },
    ensRegistry: {
      address: '0x713fB74467A03d44Aea31BC5d34F8bedE07a34b0',
    },
    ensReverseRegistrar: {
      address: '0xCF01737633d45256872a604590613AF4197127B5',
    },
    ensUniversalResolver: {
      address: '0xae2A6B9cA0E8aA22eD78a7299B0725BD397898b4',
    },
    ensMetadataService: {
      address: '0x757F324F5629f2BDdBaa09772FeC7dccc4be23Ac',
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  subgraphs: {
    ens: {
      url: 'https://api.studio.thegraph.com/query/91249/3ns-arbitrum-sepolia/version/latest',
    },
  },
}

export const moonbaseAlphaWithEns = {
  ...moonbaseAlpha,

  contracts: {
    ensBaseRegistrarImplementation: {
      address: '0xB3b854325b78Ca5668C17bDf094F63dF51b5A983',
    },
    ensBulkRenewal: {
      address: '0xF3336e52694cfE3924439bd4bEeB088f33Cc1080',
    },
    ensDnsRegistrar: {
      address: '0xCfB0143915213d4dDEEff48e3c061218404350B5',
    },
    ensDnssecImpl: {
      address: '0x30FaF32F29b48cD0f14B9c6BE2432E72a09F8a81',
    },
    ensEthRegistrarController: {
      address: '0x531b7c54cDAe40bc3d6A664d281Aa494D5C70025',
    },
    ensNameWrapper: {
      address: '0xEa83e52A6486Da4139bDe138BEd760D004aaB8C4',
    },
    ensPublicResolver: {
      address: '0x3d4f606b3dc1A152a2cEA37d3eBB05b687FaB554',
    },
    ensRegistry: {
      address: '0x2cF6fa73d8D0562d9a20DaEd5aa7eCC479d7C0b4',
    },
    ensReverseRegistrar: {
      address: '0x147Ee165Cdcc79A7266783Eb003dEEE0e5CA95D6',
    },
    ensUniversalResolver: {
      address: '0xD54b163F2da746EFC486C990990Ee4583e2f0f5F',
    },
    ensMetadataService: {
      address: '0x046Fa1e7E3257cB9f4db04c2538D3327D15f0920',
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  subgraphs: {
    ens: {
      url: 'https://api.studio.thegraph.com/query/91249/3ns-moonbasealpha/version/latest',
    },
  },
}

export const chainsWithEns = [moonbaseAlphaWithEns, arbitrumSepoliaWithEns] as const

export const getSupportedChainById = (chainId: number | undefined) =>
  chainId ? chainsWithEns.find((c) => c.id === chainId) : undefined

export type SupportedChain =
  | typeof moonbaseAlphaWithEns
  | typeof arbitrumSepolia
  | typeof localhostWithEns
